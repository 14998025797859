:root .dashboard {
  --ion-background-color: var(--ion-color-light);
}

:root .dashboard .ios {
  --ion-safe-area-top: 40px;
}

:root .dashboard .md {
  --ion-safe-area-top: 20px;
}

.dashboard ion-col {
  --ion-grid-column-padding: 0;
}

.dashboard .md ion-card-subtitle {
  text-transform: uppercase;
}

.dashboard ion-card {
  border-radius: 8px;
  --ion-background-color: #fff;
}

.dashboard .ion-item,
.dashboard .ion-label,
.dashboard .ion-label {
  --ion-background-color: #fff;
}

.dashboard ion-card-content {
  padding: 4px 10px;
}

.dashboard ion-label {
  font-weight: 700;
  font-size: 1.8rem !important;
}

/*
.ios, .md {
    --ion-background-color: var(--ion-color-light);
}
*/

ion-modal.date-modal ion-datetime {
  height: auto;
  width: auto;
  height: 400px;
  max-width: 380px;
}

ion-modal.date-modal {
  --width: 340px;
  --height: 400px;
  --border-radius: 8px;
}
