.error ion-label,
.error ion-input {
    color: red !important;
}
.error ion-input {
    border-bottom: 1px solid red;
}

:root .dashboard .ios {
    --ion-safe-area-top: 100px;
}

:root .dashboard .md {
    --ion-safe-area-top: 20px;
}