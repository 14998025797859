.sheet-action {
    --backdrop-opacity: 0.25;
    --background: red;
}

.sheet-action .action-sheet-group{
    --background:red;
    --button-background: blue;
}

.confirm-action-sheet {
    --button-color: var(--ion-color-success);
    --color: var(--ion-color-success),
}

.danger-action-sheet {
    --button-color: var(--ion-color-danger);
    --color: var(--ion-color-danger),
}