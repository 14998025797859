ion-card.counter {
  margin: 10px;
}

.counter ion-item {
  --padding-start: 10px;
  --padding-top: 10px;
}

.counter ion-grid ion-row:last-child {
  padding-top: 5px;
}

.counter ion-row ion-col:first-child {
  text-align: left;
}

.counter ion-row ion-col:last-child {
  text-align: right;
}
